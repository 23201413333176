<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Location Rental License Settings" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="5">
        <v-autocomplete
          v-model="selectedOrganization"
          :items="organizationNames"
          color="primaryAccent"
          dense
          hide-details
          label="Organization"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="5">
        <v-autocomplete
          v-model="selectedClinic"
          :items="clinicNames"
          :readonly="!selectedOrganization"
          color="primaryAccent"
          dense
          hide-details
          label="Clinic"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="5">
        <v-autocomplete
          v-model="selectedLocation"
          :items="locationNames"
          :readonly="!selectedClinic"
          color="primaryAccent"
          dense
          hide-details
          label="Location"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <template v-if="selectedOrganization && selectedClinic && selectedLocation">
      <v-row justify="center">
        <v-col cols="10">
          <rental-license-counter :locationID="getSelectedLocation" @editModeChanged="switchEditMode" />
        </v-col>
      </v-row>
    </template>
    <save-request :showSaveRequest="showSaveRequest" @closeSaveRequest="showSaveRequest = false" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import RentalLicenseCounter from '../../components/LicSrvComponents/RentalLicenseCounter.vue';
import SaveRequest from '../../components/LicSrvComponents/SaveRequest.vue';
import ClinicsService from '../../services/clinics.service';
import OrganizationsService from '../../services/organizations.service';
import requiredPermissions from '../../requiredPermissions';
import LocationsService from "../../services/locations.service";
import { assignSeveralProperties } from "../../functions/assignPropertyIfExists";

export default {
  name: 'RentalSettings',
  components: { RentalLicenseCounter, SaveRequest },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    getSelectedLocation() {
      return this.selectedLocation;
    },
  },
  data() {
    return {
      // visualization
      editMode: false,
      showSaveRequest: false,
      // selection data
      selectedOrganization: null,
      selectedClinic: null,
      selectedLocation: null,
      organizationNames: [],
      clinicNames: [],
      locationNames: [],
      preSelection: {
        organization: null,
        clinic: null,
        location: null,
      },
      hasPermission: false,
    };
  },
  watch: {
    selectedOrganization: {
      handler() {
        this.fetchClinicNames();
        this.$store.commit('selectedProperties/setRentalLicenseSettingsOrganization', this.selectedOrganization);
      },
    },
    selectedClinic: {
      handler() {
        this.fetchLocationNames();
        this.$store.commit('selectedProperties/setRentalLicenseSettingsClinic', this.selectedClinic);
      }
    },
    selectedLocation: {
      handler() {
        this.$store.commit('selectedProperties/setRentalLicenseSettingsLocation', this.selectedLocation);
      }
    }
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.rentalLicense, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchOrganizationNames();
          assignSeveralProperties([this.$route.params, this.$route.query], ['organization', 'clinic', 'location'], this.preSelection, this.$store, 'rentalLicenseSettings')
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  },
  methods: {
    switchEditMode(value) {
      this.editMode = value;
    },
    fetchOrganizationNames() {
      if (this.organizationNames.length === 0) {
        OrganizationsService.getOrganizationNames()
          .then((organizationResponse) => {
            organizationResponse.data.forEach((orga) => {
              this.organizationNames.push({
                value: orga.OrganizationID,
                text: orga.name,
              });
            });
            if (this.preSelection.organization) {
              this.selectedOrganization = this.preSelection.organization;
              this.preSelection.organization = null;
            } else {
              this.selectedOrganization = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchClinicNames() {
      this.clinicNames = [];
      if (this.selectedOrganization) {
        ClinicsService.getClinicNames(this.selectedOrganization)
          .then((clinicResponse) => {
            clinicResponse.data.forEach((clinic) => {
              this.clinicNames.push({
                value: clinic.ClinicID,
                text: clinic.name,
              });
            });
            if (this.preSelection.clinic) {
              this.selectedClinic = this.preSelection.clinic;
              this.preSelection.clinic = null;
            } else {
              this.selectedClinic = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchLocationNames() {
      this.locationNames = [];
      if (this.selectedClinic) {
        LocationsService.getLocationNames(this.selectedClinic)
          .then((locationResponse) => {
            locationResponse.data.forEach((location) => {
              this.locationNames.push({
                value: location.LocationID,
                text: location.name,
              });
            });
            if (this.preSelection.location) {
              this.selectedLocation = this.preSelection.location;
              this.preSelection.location = null;
            } else {
              this.selectedLocation = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    }
  },
};
</script>

<style></style>
