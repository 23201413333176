<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="auto">
            <mex-p content="Set cycle type thresholds" fontSize="h5" fontWeight="bold-italic" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <mex-sperm-spinner v-if="cycleTypesThresholdsLoading" :spinnerText="cycleTypesThresholdsLoadingText" />
            <transition-group v-else name="cycleTypesGroup" tag="div">
              <v-layout row wrap :key="'cycleTypesGroupLayout'">
                <v-col v-for="(cycleTypesGroup, cycleTypesGroupIdx) in cycleGroups" :key="cycleTypesGroup.cycleTypesGroupID" v-bind:class="'col-12 col-lg-6 col-xl-4'">
                  <mex-sheet rounded>
                    <v-row>
                      <v-col cols="8">
                        <!-- Cycle Group Template ------------------------------------------------------------------------------>
                        <v-autocomplete
                          v-model="cycleTypesGroup.cycleGroupTemplate.name"
                          :items="cycleGroupTemplateNames"
                          :readonly="!editMode"
                          color="primaryAccent"
                          dense
                          hide-details
                          label="Cycle Group Template"
                          outlined
                          class="headline-autocomplete"
                          @input="newValue => loadCycleTypesOfTemplate(newValue, cycleTypesGroup)"
                        />
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col align-self="start" cols="1">
                        <v-row>
                          <mex-btn :disabled="!editMode" icon="mdi-close" icon-only @click="removeCycleTypeGroup(cycleTypesGroupIdx)" />
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-divider />
                      </v-col>
                      <v-col cols="8">
                        <!-- Type Selectors ------------------------------------------------------------------------------------>
                        <v-row
                          v-for="(cycleTypeSelector, cycleTypeSelectorIdx) in cycleTypesGroup.cycleTypeGroups"
                          :key="`cycleTypesGroup-${cycleTypesGroupIdx}-cycleTypeSelector-${cycleTypeSelectorIdx}`"
                        >
                          <v-col align-self="center" cols="10">
                            <v-autocomplete
                              v-model="cycleTypeSelector.selectedCycleType"
                              :items="cycleTypes"
                              readonly
                              disabled
                              color="primaryAccent"
                              dense
                              hide-details
                              label="Cycle Types"
                              outlined
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-divider />
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-col align-self="center" cols="1">
                        <v-icon large>mdi-equal</v-icon>
                      </v-col>
                      <v-col align-self="center" cols="4">
                        <v-col align-self="center" cols="11">
                          <v-row justify="center">
                            <!-- Counter Threshold ---------------------------------->
                            <v-autocomplete
                              v-model="cycleTypesGroup.cycleThresholdCategory"
                              item-value="CycleThresholdCategoryID"
                              :items="thresholdCategories"
                              color="primaryAccent"
                              :readonly="!editMode"
                              dense
                              hide-details
                              label="Threshold"
                              outlined
                              return-object
                            >
                              <template v-slot:selection="{ attr, on, item, selected }">
                                <span v-bind="attr" v-on="on" class="white--text" color="foreground">{{ item.minValue + ' - ' + item.maxValue }}</span>
                              </template>
                              <template v-slot:item="{ item }">
                                <v-list-item-avatar color="primaryAccent" class="text-h5 font-weight-light white--text">{{ item.CycleThresholdCategoryID }}</v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>{{ item.minValue + ' - ' + item.maxValue }}</v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </v-row>
                        </v-col>
                      </v-col>
                    </v-row>
                  </mex-sheet>
                </v-col>
              </v-layout>
            </transition-group>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="11">
            <mex-btn :disabled="!editMode" content="Add a counter" icon="mdi-plus-box" width="100%" @click="addCycleTypeCounter" />
          </v-col>
          <v-col cols="1">
            <mex-btn :disabled="!editMode" icon="mdi-flash" iconOnly @click="addCycleTypesByRegister" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <mex-btn v-if="writeRentalLicense && !editMode" content="Edit" @click="editMode = true" />
        <mex-btn v-if="editMode" :disabled="!validCycleGroups" content="Save Changes" @click="saveChanges" />
      </v-col>
      <v-col cols="auto">
        <mex-btn v-if="editMode" content="Cancel" @click="closeEditMode" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RegisterTypesService from '../../services/registerTypes.service';
import CycleTypesService from '../../services/cycleTypes.service';
import ClinicsService from '../../services/clinics.service';
import ClinicCycleTypeThresholdsService from '../../services/clinicCycleTypeThresholds.service';
import CycleGroupsService from "../../services/cycleGroups.service";
import CycleGroupTemplatesService from "../../services/cycleGroupTemplates.service";
import CycleThresholdCategoriesService from "../../services/cycleThresholdCategories.service";
import LocationsService from "../../services/locations.service";
import MexBtn from "../MedITEX_Vue_Components/MexComponents/MexButton";
// import keyListenerManager from '../../functions/keyListenerManager';

export default {
  components: { MexBtn },
  name: 'RentalLicenseCounter',
  props: {
    locationID: {
      type: Number,
      default: null,
      description: '',
    },
  },
  data() {
    return {
      // visualization
      editMode: false,
      editModeAndSaveShortCutListener: null,
      boundEditModeAndSaveShortCutListener: null,
      showSaveRequest: false,
      cycleTypesThresholdsLoading: false,
      cycleTypesThresholdsLoadingText: '',
      // data
      registerTypes: [],
      cycleTypeGroups: [],
      cycleTypes: [],
      cycleGroups: [],
      cycleTypeSelectorTemplate: {
        selectedCycleType: null,
      },
      cycleTypesGroupTemplate: {
        cycleGroupTemplate: {
          name: null
        },
        cycleGroupsID: null,
        cycleThresholdCategory: null,
        locationID: null,
        cycleTypeGroups: [],
      },
      showCycleTypes: false,
      thresholdCategories: [],
      cycleGroupTemplateNames: [],
    };
  },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    writeRentalLicense() {
      return this.getUserPermissions.includes('write_License Administration_Rental License');
    },
    validCycleGroups() {
      for (let i = 0; i < this.cycleGroups.length; i++) {
        if (!this.cycleGroups[i].cycleThresholdCategory) {
          return false;
        }
        if (!this.cycleGroups[i].cycleGroupTemplate) {
          return false;
        }
      }
      return true;
    },
  },
  watch: {
    locationID: {
      handler() {
        this.getCycleCategoryGroups();
      },
    },
    editMode: {
      handler() {
        if (!this.editMode) {
          this.getCycleCategoryGroups();
        }
        this.$emit('editModeChanged', this.editMode);
      },
    },
  },
  methods: {
    loadCycleTypesOfTemplate(cycleGroupTemplateName, cycleGroup) {
      CycleGroupTemplatesService.getCycleTypesOfTemplate(cycleGroupTemplateName)
        .then((cycleTypeGroups) => {
          cycleGroup.cycleTypeGroups = cycleTypeGroups.data.cycleTypeGroups.map((cycleTypeGroup) => {
            return {
              selectedCycleType: cycleTypeGroup.CycleTypeID
            }
          });
        })
        .catch((err) => {
          this.$toast.error('Problem while loading cycle-types of templates');
        });
    },
    getCycleCategoryGroups() {
      this.cycleTypesThresholdsLoadingText = 'Loading location cycle types and threshold-categories';
      this.cycleTypesThresholdsLoading = true;
      this.editMode = false;
      this.cycleGroups = [];
      CycleGroupsService.getCycleGroupsOfLocation(this.locationID)
        .then((cycleGroups) => {
          this.cycleTypesThresholdsLoading = false;
          this.cycleGroups = cycleGroups.data.cycleGroups.map(
            (group) => {
              return {
                cycleTypesGroupID: group.CycleGroupID,
                cycleThresholdCategory: group.CycleThresholdCategory,
                locationID: group.LocationID,
                cycleTypeGroups: group.CycleGroupTemplate.CycleTypeGroups.map((cycleTypeGroup) => {
                  return {
                    selectedCycleType: cycleTypeGroup.CycleTypeID
                  }
                }),
                cycleGroupTemplate: group.CycleGroupTemplate,
              }
            }
          );
        })
        .catch((err) => {
          this.$toast.error('Problem while loading cycle-category-groups');
        });
    },
    fillCycleTypeSelector() {
      return new Promise((resolve, reject) => {
        RegisterTypesService.getRegisterTypeNames()
          .then((registerTypesResponse) => {
            registerTypesResponse.data
              .filter((x) => x.RegisterTypeID !== 1)
              .forEach((registerType) => {
                const entry = {
                  value: registerType.RegisterTypeID,
                  text: registerType.acronym,
                  description: '',
                };
                registerType.CycleTypes.forEach((type) => {
                  entry.description += `${type.name} | `;
                });
                this.registerTypes.push(entry);
              });
            CycleTypesService.getCycleTypeNames()
              .then((cycleTypesResponse) => {
                cycleTypesResponse.data.forEach((cycleType) => {
                  this.cycleTypes.push({
                    value: cycleType.CycleTypeID,
                    text: cycleType.name,
                  });
                });
                CycleThresholdCategoriesService.getCycleThresholdCategories()
                  .then((cycleThresholdCategories) => {
                    this.thresholdCategories = cycleThresholdCategories.data.cycleThresholdCategories;
                    CycleGroupTemplatesService.getCycleGroupTemplatesNames()
                      .then((cycleGroupTemplateNamesResponse) => {
                        this.cycleGroupTemplateNames = cycleGroupTemplateNamesResponse.data.cycleGroupTemplateNames.map(name => name.name);
                        resolve();
                      })
                      .catch((err) => {
                        this.$toast.error('Problem while loading Cycle-Group-Templates-Names');
                        reject(err);
                      });
                  })
                  .catch((err) => {
                    this.$toast.error('Problem while loading Threshold-Categories');
                    reject(err);
                  });
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            this.$toast.error(err.message);
            reject(err);
          });
      });
    },
    addCycleTypeSelector(cycleTypesGroup) {
      const newCycleTypeSelector = JSON.parse(JSON.stringify({ ...this.cycleTypeSelectorTemplate }));
      cycleTypesGroup.cycleTypeGroups.push(JSON.parse(JSON.stringify({ ...newCycleTypeSelector })));
    },
    addCycleTypeCounter() {
      const newCycleTypeGroup = JSON.parse(JSON.stringify({ ...this.cycleTypesGroupTemplate }));
      newCycleTypeGroup.cycleTypeGroups.push(JSON.parse(JSON.stringify({ ...this.cycleTypeSelectorTemplate })));
      newCycleTypeGroup.locationID = this.locationID;
      this.cycleGroups.push(JSON.parse(JSON.stringify({ ...newCycleTypeGroup })));
    },
    removeCycleTypeSelector(cycleTypesGroupIdx, cycleTypeSelectorIdx) {
      this.cycleGroups[cycleTypesGroupIdx].cycleTypeGroups.length > 1
        ? this.cycleGroups[cycleTypesGroupIdx].cycleTypeGroups.splice(cycleTypeSelectorIdx, 1)
        : this.cycleGroups[cycleTypesGroupIdx].cycleTypeGroups = [JSON.parse(JSON.stringify({...this.cycleTypeSelectorTemplate}))];
    },
    removeCycleTypeGroup(cycleTypesGroupIdx) {
      this.cycleGroups.splice(cycleTypesGroupIdx, 1);
    },
    addCycleTypesByRegister() {
      CycleGroupTemplatesService.getCycleGroupTemplatesOfLocationsRegisterType(this.locationID)
        .then(async (cycleGroupTemplatesResponse) => {
          if (cycleGroupTemplatesResponse.data.cycleGroupTemplates.length) {
            for (let i = 0; i < cycleGroupTemplatesResponse.data.cycleGroupTemplates.length; i++) {
              const cycleGroupTemplate = cycleGroupTemplatesResponse.data.cycleGroupTemplates[i];
              this.addCycleTypeCounter();
              this.cycleGroups[this.cycleGroups.length - 1].cycleGroupTemplate.name = cycleGroupTemplate.name;
              await this.loadCycleTypesOfTemplate(cycleGroupTemplate.name, this.cycleGroups[this.cycleGroups.length - 1]);
            }
          } else {
            this.$toast.info('No Cycle-Group-Templates for selected location found');
          }
        })
        .catch((err) => {
          this.$toast.error('Error while loading templates for this location');
        });
    },
    saveChanges() {
      CycleGroupsService.createCycleTypesGroups(this.locationID, this.cycleGroups)
        .then(() => {
          CycleThresholdCategoriesService.getCycleThresholdCategories(this.locationID)
            .then(() => {
              this.fillCycleTypeSelector()
                .then(() => {
                  this.$toast.success('Updated license-settings');
                  this.editMode = false;
                })
                .catch((err) => {
                  this.$toast.error('Problem while filling cycle-type-selector');
                });
            })
        })
        .catch((err) => {
          this.$toast.error('Problem while updating license-settings: ' + err);
        });
    },
    closeEditMode() {
      this.editMode = false;
    },
  },
  created() {
    this.fillCycleTypeSelector()
      .then(() => {
        this.getCycleCategoryGroups();
      })
      .catch((err) => {
        this.$toast.error('Problem while filling cycle-type-selector');
      });
  },
};
</script>

<style>
.headline-autocomplete {
  font-size: 18px;
}

</style>
