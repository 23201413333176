import httpClient from '../httpClient/httpClient';

const CycleGroupsService = {
  getCycleGroupsOfLocation(locationID) {
    return httpClient.get('api/get-cycle-groups-of-location', {
      params: {
        locationID
      },
    });
  },

  createCycleTypesGroups(locationID, cycleGroups) {
    return httpClient.post('api/create-cycle-groups', {
      locationID,
      cycleGroups,
    });
  },
  getLocationCycleOverview(page, size) {
    return httpClient.get("api/get-location-cycle-overview", {
      params: {
        page,
        size,
      }
    });
  },
  getCycleTypeGroups(retrievalDate, dataType) {
    return httpClient.get("api/get-cycle-type-groups-file", {
      params: {
        retrievalDate,
        dataType
      }
    });
  },
  getAvailableBackupDates() {
    return httpClient.get("api/get-available-backup-dates");
  },
  getLocationCycleOverviewFromBackup(backupDate) {
    return httpClient.get("api/get-location-cycle-overview-from-backup", {
      params: {
        backupDate
      }
    })
  }
};

export default CycleGroupsService;
